import axios from 'axios';

const state = {
    token: null,
    currentUser: null
}

const mutations = {
    SET_TOKEN (state, token) {
        state.token = token;
    },
    setCurrentUser (state, user) {
        state.currentUser = user;
    }
}

const actions = {
    login ({ commit, dispatch }, data) {
        return axios.post(process.env.VUE_APP_WMS_API + '/auth/login', data)
        .then((response) => {
            localStorage.setItem("token", response.data.accessToken);
            commit('SET_TOKEN', response.data.accessToken);
            dispatch("currentUser");
        })
    },
    init({dispatch}){
        return Promise.all([
            dispatch('currentUser'),
        ])
    },
    currentUser({ commit }){
        if(state.currentUser !== null)
            return state.currentUser;

        return axios.get(process.env.VUE_APP_WMS_API +  '/auth/user', null, {
            headers: {'Authorization' : 'Bearer ' + localStorage.getItem("token")}
        }).then((response) => {
            commit('setCurrentUser', response.data);
        });
    },
    logout ({ commit }) {
        localStorage.removeItem("token");
        commit('SET_TOKEN', null);
        commit('setCurrentUser', null);
    }
}

const getters = {
    token: state => state.token,
    currentUser: state => state.currentUser
}

const loginModule = {
    state,
    mutations,
    actions,
    getters
}

export default loginModule;
