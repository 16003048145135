import { io } from 'socket.io-client';

class SocketioService {
    socket;

    setupSocketConnection() {
        this.socket = io(process.env.VUE_APP_WMS_API_WEBSOCKET + '/global', { autoConnect: true, transports: ['polling'] });
    }

    setAndGetSocketConnection() {
        if (!this.socket) {
            this.setupSocketConnection();
        }

        return this.socket;
    }

    subscribeToGlobalMessages(cb) {
        this.socket = this.setAndGetSocketConnection();
        if (!this.socket) return true;
        this.socket.on('globalMessage', (msg) => {
            return cb(null, msg);
        });
    }

    subscribeToOrderCollectionPalletMeasuring(cb) {
        this.socket = this.setAndGetSocketConnection();
        if (!this.socket) return true;
        this.socket.on('orderCollectionPalletMeasuring', (msg) => {
            return cb(null, msg);
        });
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}

export default new SocketioService();
