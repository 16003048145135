import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import i18n from '@/libs/i18n';
import router from './router';
import store from './store';
import axios from 'axios';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import '@/libs/tour';

// Composition API
Vue.use(VueCompositionAPI);

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

export const bus = new Vue();

import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
  name: '_blank',
  specs: ['titlebar=yes', 'scrollbars=yes', 'fullscreen=yes'],
  styles: ['./assets/scss/print.css'],
};

Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.common['Authorization'] = 'Bearer ' + token;
    }

    const user = store.getters.currentUser;
    config.headers.common['x-custom-lang'] = user && user.language ? user.language : 'ro';
    config.headers.common['Content-Type'] = 'application/json';
    config.headers.common['Accept'] = 'application/json';

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 400:
          console.log(error.response.data.message);
          break;
        case 401:
          store.dispatch('logout').then(() => {
            router.replace({ path: '/login' });
          });
          Vue.swal('Autentificarea a esuat, te rugam sa te autentifici!', error.response.data.message, 'error');

          break;
        case 403:
          Vue.swal('Nu ai access aici!', 'Nu ai permisiuni sa faci aceasta modificare!', 'error');
          break;

        case 502:
          store.dispatch('logout').then(() => {
            router.replace({ path: '/login' });
          });
      }
      return Promise.reject(error.response);
    }
  },
);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
