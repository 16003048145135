import { $themeConfig } from '@themeConfig';
export default [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/views/pages/dashboard/Dashboard.vue'),
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/error/Error404.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            action: 'read',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
    },
    {
        path: '/stock',
        name: 'stock',
        component: () => import('@/views/pages/stock/Stock.vue'),
        meta: {
            pageTitle: 'Stock',
            breadcrumb: [
                {
                    text: 'Stock',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: () => import('@/views/pages/task/Task.vue'),
        meta: {
            pageTitle: 'Task',
        },
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/pages/users/Users.vue'),
        meta: {
            pageTitle: 'Users',
            authorize: ['Admin'],
        },
    },
    {
        path: '/placements',
        name: 'placements',
        component: () => import('@/views/pages/placements/Placements.vue'),
        meta: {
            pageTitle: 'Placements',
            authorize: ['Admin'],
        },
    },
    {
        path: '/user/create/',
        name: 'users-create',
        component: () => import('@/views/pages/users/UsersCreate.vue'),
        meta: {
            pageTitle: 'Create user',
            authorize: ['Admin'],
        },
    },
    {
        path: '/user/edit/:id',
        name: 'users-edit',
        component: () => import('@/views/pages/users/UsersEdit.vue'),
        meta: {
            authorize: ['Admin'],
            pageTitle: 'Edit user',
            breadcrumb: [
                {
                    text: 'Edit',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/products',
        name: 'products',
        component: () => import('@/views/pages/product/Products.vue'),
        meta: {
            pageTitle: 'Products',
        },
    },
    {
        path: '/products/missing',
        name: 'products-missing',
        component: () => import('@/views/pages/product/ProductsMissing.vue'),
        meta: {
            pageTitle: 'Products Missing',
        },
    },
    {
        path: '/barcodes',
        name: 'barcodes',
        component: () => import('@/views/pages/bar-codes/BarCodes.vue'),
        meta: {
            pageTitle: 'Barcodes',
            breadcrumb: [
                {
                    text: 'Barcodes',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/suggestions',
        name: 'suggestions',
        component: () => import('@/views/pages/suggestions/Suggestions.vue'),
        meta: {
            pageTitle: 'Suggestions',
            breadcrumb: [
                {
                    text: 'Suggestions',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/print-code',
        name: 'printcode',
        component: () => import('@/views/pages/print/Print.vue'),
        meta: {
            pageTitle: 'Print Code',
            breadcrumb: [
                {
                    text: 'Print Code',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/app-settings',
        name: 'app-settings',
        component: () => import('@/views/pages/settings/Settings.vue'),
        meta: {
            pageTitle: 'Settings',
            authorize: ['Admin'],
            breadcrumb: [
                {
                    text: 'Settings',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/checkin',
        name: 'checkin',
        component: () => import('@/views/pages/check-in/CheckIn.vue'),
        meta: {
            pageTitle: 'Checkin',
            breadcrumb: [
                {
                    text: 'Checkin',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/reception',
        name: 'reception',
        component: () => import('@/views/pages/check-in/Reception.vue'),
        meta: {
            pageTitle: 'Reception',
            breadcrumb: [
                {
                    text: 'Reception',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/nircollection',
        name: 'nir-collection',
        component: () => import('@/views/pages/check-in/ReceptionCollection.vue'),
        meta: {
            pageTitle: 'NIR Collection',
            breadcrumb: [
                {
                    text: 'NIR Collection',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/suppliers',
        name: 'suppliers',
        component: () => import('@/views/pages/suppliers/Suppliers.vue'),
        meta: {
            pageTitle: 'Suppliers',
            breadcrumb: [
                {
                    text: 'Suppliers',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/picking/list/',
        name: 'picking-list',
        component: () => import('@/views/pages/picking/PickingList.vue'),
        meta: {
            pageTitle: 'Picking List',
        },
    },
    {
        path: '/picking/create',
        name: 'picking',
        component: () => import('@/views/pages/picking/Picking.vue'),
        meta: {
            pageTitle: 'Create picking',
        },
    },
    {
        path: '/service',
        name: 'service',
        component: () => import('@/views/pages/service/Service.vue'),
        meta: {
            pageTitle: 'Service',
            routeDisabled: $themeConfig.appDisabledModule.service,
        },
    },
    {
        path: '/packing',
        name: 'packing',
        component: () => import('@/views/pages/packing/Packing.vue'),
        meta: {
            pageTitle: 'Packing',
        },
    },
    {
        path: '/packing/final',
        name: 'packing-final',
        component: () => import('@/views/pages/packing/FinalPacking.vue'),
        meta: {
            pageTitle: 'Packing',
        },
    },
    {
        path: '/carriers',
        name: 'carriers',
        component: () => import('@/views/pages/carriers/Carriers.vue'),
        meta: {
            pageTitle: 'Carriers',
        },
    },
    {
        path: '/returns',
        name: 'returns',
        component: () => import('@/views/pages/return/Return.vue'),
        meta: {
            pageTitle: 'Returns',
        },
    },
    {
        path: '/transport-estimation',
        name: 'transport-estimation',
        component: () => import('@/views/pages/shipping/CostEstimation.vue'),
        meta: {
            pageTitle: 'Transport Cost Estimation',
        },
    },
    {
        path: '/edit/transport',
        name: 'edit-transport',
        component: () => import('@/views/pages/shipping/Transport.vue'),
        meta: {
            pageTitle: 'Edit Transport',
        },
    },
    {
        path: '/edit/transport-details',
        name: 'edit-transport-details',
        component: () => import('@/views/pages/shippingDetails/TransportDetails.vue'),
        meta: {
            pageTitle: 'Edit Transport Details',
        },
    },
    {
        path: '/reports/user',
        name: 'reports-user',
        component: () => import('@/views/pages/reports/ReportsUser.vue'),
        meta: {
            pageTitle: 'Reports User',
        },
    },
    {
        path: '/reports/picking',
        name: 'reports-picking',
        component: () => import('@/views/pages/reports/Reports.vue'),
        meta: {
            pageTitle: 'Reports Picking',
        },
    },
    {
        path: '/logs',
        name: 'logs',
        component: () => import('@/views/pages/logs/Logs.vue'),
        meta: {
            pageTitle: 'Logs',
            breadcrumb: [
                {
                    text: 'Logs',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/faq/edit/:id',
        name: 'pages-faq-edit',
        component: () => import('@/views/pages/faq/FaqEdit.vue'),
        meta: {
            pageTitle: 'FAQ Edit',
        },
    },
    {
        path: '/pages/faq',
        name: 'pages-faq',
        component: () => import('@/views/pages/faq/Faq.vue'),
        meta: {
            pageTitle: 'FAQ',
            breadcrumb: [
                {
                    text: 'Pages',
                },
                {
                    text: 'FAQ',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/pages/faq/new',
        name: 'pages-faq-new',
        component: () => import('@/views/pages/faq/FaqNew.vue'),
        meta: {
            pageTitle: 'FAQ',
            breadcrumb: [
                {
                    text: 'Pages',
                },
                {
                    text: 'FAQ',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/inventory',
        name: 'inventory',
        component: () => import('@/views/pages/inventory/Inventory.vue'),
        meta: {
            pageTitle: 'Inventory',
        },
    },
    {
        path: '/inventory-locations/list/:id',
        name: 'inventory-locations',
        component: () => import('@/views/pages/inventory/InventoryLocations.vue'),
        meta: {
            pageTitle: 'Inventory locations',
            breadcrumb: [
                {
                    text: 'Inventory',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/certificate',
        name: 'certificate',
        component: () => import('@/views/pages/certificate/Certificate.vue'),
        meta: {
            pageTitle: 'Certificate',
            routeDisabled: $themeConfig.appDisabledModule.certification,
        },
    },
    {
        path: '/client-certificate',
        name: 'client-certificate',
        component: () => import('@/views/pages/certificate/client-certificate/ClientCertificate.vue'),
        meta: {
            pageTitle: 'Client certificates',
            routeDisabled: $themeConfig.appDisabledModule.certification,
        },
    },
    {
        path: '/product-kits',
        name: 'product-kits',
        component: () => import('@/views/pages/product-kits/ProductKits.vue'),
        meta: {
            pageTitle: 'Product Kits',
        },
    },
];
