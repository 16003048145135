import Vue from 'vue'
import VueRouter from 'vue-router'
import pages from './routes/pages'
import store from "../store";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {

  if (to.name !== "login" && localStorage.getItem("token") === null && to.name !== "homepage")
    next({ name: "login" });
  else if ((to.name === "login" || to.name === "homepage") && localStorage.getItem("token") !== null)
    next({ name: "dashboard" });
  if (to.name !== "login") {
    store.dispatch("currentUser").then(() => {
      const { authorize, routeDisabled } = to.meta;
      const currentUser = store.getters.currentUser;

      if (routeDisabled) {
        next({ name: "login" });
        return;
      }

      if (authorize && authorize.length && !authorize.includes(currentUser.roles[0])) {
        next({ name: "login" });
        return;
      }
      next();
    });
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
